import Vue from "vue";
import VueRouter from "vue-router";
import Homepage from "@/views/Homepage.vue";
import login from "../views/Login.vue";
import forgotPassword from "@/views/forgotPassword.vue";
import forgotPasswordSuccess from "@/views/forgotPasswordSuccess.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  { path: "/forgotpassword", name: "forgot", component: forgotPassword },
  {
    path: "/forgotPasswordSuccess",
    name: "forgotSuccess",
    component: forgotPasswordSuccess,
  },
  // {
  //   path: "/ExportVat",
  //   name: "ExportVat",
  //   component: () => import("@/views/ExportExcel/ExportVat.vue"),
  // },
  // {
  //   path: "/invc",
  //   name: "invc",
  //   component: () => import("@/views/ExportExcel/ExportInvoiceeee.vue"),
  // },
  {
    path: "/Home",
    component: Homepage,
    children: [
      {
        path: "/ManageOfficer",
        name: "ManageOfficer",
        component: () => import("@/views/ManageOfficer"),
      },
      {
        path: "/CreateOfficer",
        name: "CreateOfficer",
        component: () => import("@/components/Officer/CreateOfficer.vue"),
      },
      {
        path: "/EditOfficer",
        name: "EditOfficer",
        component: () => import("@/components/Officer/EditOfficer.vue"),
      },
      {
        path: "/ViewOfficer",
        name: "ViewOfficer",
        component: () => import("@/components/Officer/ViewOfficer.vue"),
      },
      {
        path: "/ManageSite",
        name: "ManageSite",
        component: () => import("@/views/ManageSite"),
      },
      {
        path: "/CreateSite",
        name: "CreateSite",
        component: () => import("@/components/Site/CreateSite.vue"),
      },
      {
        path: "/EditSite",
        name: "EditSite",
        component: () => import("@/components/Site/EditSite.vue"),
      },
      {
        path: "/ViewSite",
        name: "ViewSite",
        component: () => import("@/components/Site/ViewSite.vue"),
      },
      {
        path: "/ManageLeave",
        name: "ManageLeave",
        component: () => import("@/views/ManageLeave"),
      },
      {
        path: "/CreateLeave",
        name: "CreateLeave",
        component: () => import("@/components/Leave/CreateLeave.vue"),
      },
      {
        path: "/EditLeave",
        name: "EditLeave",
        component: () => import("@/components/Leave/EditLeave.vue"),
      },
      {
        path: "/ViewLeave",
        name: "ViewLeave",
        component: () => import("@/components/Leave/ViewLeave.vue"),
      },
      {
        path: "/ManageSummary",
        name: "ManageSummary",
        component: () => import("@/views/ManageSummary"),
      },
      {
        path: "/CreateSummary",
        name: "CreateSummary",
        component: () => import("@/components/Summary/CreateSummary.vue"),
      },
      {
        path: "/EditSummary",
        name: "EditSummary",
        component: () => import("@/components/Summary/EditSummary.vue"),
      },
      {
        path: "/viewSummary",
        name: "viewSummary",
        component: () => import("@/components/Summary/ViewSummary.vue"),
      },
      {
        path: "/Managenews",
        name: "Managenews",
        component: () => import("@/views/Managenews"),
      },
      {
        path: "/Createnews",
        name: "Createnews",
        component: () => import("@/components/news/Createnews.vue"),
      },
      {
        path: "/Editnews",
        name: "Editnews",
        component: () => import("@/components/news/Editnews.vue"),
      },
      {
        path: "/Viewnews",
        name: "Viewnews",
        component: () => import("@/components/news/Viewnews.vue"),
      },
      {
        path: "/ManageTicket",
        name: "ManageTicket",
        component: () => import("@/views/ManageTicket"),
      },
      {
        path: "/ViewTicket",
        name: "ViewTicket",
        component: () => import("@/components/Ticket/ViewTicket.vue"),
      },
      {
        path: "/ManageTutorial",
        name: "ManageTutorial",
        component: () => import("@/views/ManageTutorial"),
      },
      {
        path: "/CreateTutorial",
        name: "CreateTutorial",
        component: () => import("@/components/Tutorial/CreateTutorial.vue"),
      },
      {
        path: "/EditTutorial",
        name: "EditTutorial",
        component: () => import("@/components/Tutorial/EditTutorial.vue"),
      },
      {
        path: "/viewTutorial",
        name: "viewTutorial",
        component: () => import("@/components/Tutorial/ViewTutorial.vue"),
      },
      {
        path: "/ManageUser",
        name: "ManageUser",
        component: () => import("@/views/ManageUser"),
      },
      {
        path: "/viewUser",
        name: "viewUser",
        component: () => import("@/components/User/viewUser.vue"),
      },
      {
        path: "/ManageCompany",
        name: "ManageCompany",
        component: () => import("@/views/ManageCompany"),
      },
      {
        path: "/CreateCompany",
        name: "CreateCompany",
        component: () => import("@/components/Company/CreateCompany.vue"),
      },
      {
        path: "/EditCompany",
        name: "EditCompany",
        component: () => import("@/components/Company/EditCompany.vue"),
      },
      {
        path: "/ViewCompany",
        name: "ViewCompany",
        component: () => import("@/components/Company/ViewCompany.vue"),
      },
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard"),
      },
      {
        path: "/Managebanners",
        name: "Managebanners",
        component: () => import("@/views/Managebanners"),
      },
      {
        path: "/Createbanners",
        name: "Createbanners",
        component: () => import("@/components/banners/Createbanners.vue"),
      },
      {
        path: "/Viewbanners",
        name: "Viewbanners",
        component: () => import("@/components/banners/Viewbanners.vue"),
      },
      {
        path: "/Editbanners",
        name: "Editbanners",
        component: () => import("@/components/banners/Editbanners.vue"),
      },
      {
        path: "/ManageLogistic",
        name: "ManageLogistic",
        component: () => import("@/views/ManageLogistic"),
      },
      {
        path: "/CreateLogistic",
        name: "CreateLogistic",
        component: () => import("@/components/Logistic/CreateLogistic.vue"),
      },
      {
        path: "/ViewLogistic",
        name: "ViewLogistic",
        component: () => import("@/components/Logistic/ViewLogistic.vue"),
      },
      {
        path: "/EditLogistic",
        name: "EditLogistic",
        component: () => import("@/components/Logistic/EditLogistic.vue"),
      },
      {
        path: "/ManageCoupon",
        name: "ManageCoupon",
        component: () => import("@/views/ManageCoupon"),
      },
      {
        path: "/CreateCoupon",
        name: "CreateCoupon",
        component: () => import("@/components/Coupon/CreateCoupon.vue"),
      },
      {
        path: "/ViewCoupon",
        name: "ViewCoupon",
        component: () => import("@/components/Coupon/ViewCoupon.vue"),
      },
      {
        path: "/EditCoupon",
        name: "EditCoupon",
        component: () => import("@/components/Coupon/EditCoupon.vue"),
      },
      {
        path: "/ManageVoucher",
        name: "ManageVoucher",
        component: () => import("@/views/ManageVoucher"),
      },
      {
        path: "/CreateVoucher",
        name: "CreateVoucher",
        component: () => import("@/components/Voucher/CreateVoucher.vue"),
      },
      {
        path: "/ViewVoucher",
        name: "ViewVoucher",
        component: () => import("@/components/Voucher/ViewVoucher.vue"),
      },
      {
        path: "/EditVoucher",
        name: "EditVoucher",
        component: () => import("@/components/Voucher/EditVoucher.vue"),
      },
      {
        path: "/Report1",
        name: "Report1",
        component: () => import("@/components/Report/Report1.vue"),
      },
      {
        path: "/Setting",
        name: "Setting",
        component: () => import("@/views/Setting.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
